



import Vue from 'vue';
import { ObjectsTypeSingleForm } from '../../store/objects/get.module';
import { Prop, Emit } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component({
  name: 'cluster-object-tabs'
})
export default class ObjectTabs extends Vue {
  @Prop({ type: String, required: true })
  value!: string;

  @Emit('change')
  changeHandler(v) {
    return v;
  }

  get items() {
    return this.$store.getters.enabledObjects
      .filter((objectsType) => this.$hasPermission(this.getViewPermissionName(objectsType)))
      .map((item) => ({ i18n: item, name: item }));
  }

  getViewPermissionName(objectsType) {
    return `ffsecurity.view_${ObjectsTypeSingleForm[objectsType]}cluster`;
  }
}
