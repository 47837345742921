


























































import ClusterItem from './item.vue';
import TableNavigation from '@/components/tables/navigation.vue';
import PageLayout from '@/components/page/layout.vue';
import ClusterObjectTabs from './object.tabs.vue';
import Component from 'vue-class-component';
import Vue from 'vue';
import {
  BodyClusterListModule,
  CarClusterListModule,
  FaceClusterListModule
} from '../../store/clusters';
import { ObjectsType } from '@/store/objects/get.module';
import { faceClusterListModule, bodyClusterListModule, carClusterListModule } from '@/store/index';
import { ITableColumn } from '@/components/tables/generator.vue';
import { TableComponentNames } from '@/components/tables/components/names';
import { Watch } from 'vue-property-decorator';

type MultipleClusterModule = FaceClusterListModule | BodyClusterListModule | CarClusterListModule;

const tableSchema: ITableColumn[] = [
  { label_i18n: 'id', prop: 'id', 'class-name': 'events-table__id-cell', width: 80 },
  { label_i18n: 'image', prop: 'cameras', 'class-name': 'cell_without_paddings', component: TableComponentNames.PolygonThumbnailPreview, width: 160 },
  { label_i18n: 'name', prop: 'name', component: TableComponentNames.AreaNameAndDescription, 'min-width': 200 },
  { label_i18n: 'created', prop: 'created_date', component: TableComponentNames.DateTime, width: 160 },
  { label_i18n: 'active,,1', prop: 'enabled', component: TableComponentNames.CounterCheckbox, width: 160, align: 'center' }
];

@Component({
  components: { TableNavigation, PageLayout, ClusterItem, ClusterObjectTabs }
})
export default class ClustersPage extends Vue {
  get objects(): string {
    return this.$route.params.objects;
  }

  get state(): MultipleClusterModule {
    return this.getModule(this.objects);
  }

  getModule(v): MultipleClusterModule {
    switch (v) {
      case ObjectsType.Bodies:
        return bodyClusterListModule;
      case ObjectsType.Cars:
        return carClusterListModule;
      default:
        return faceClusterListModule;
    }
  }

  changeObjectsHandler(v) {
    const route = this.getModule(v).getListRoute();
    route.params.objects = v;
    this.$router.push(route);
  }

  rowClickHandler({ item, column }) {
    const route = column.property === 'id' ? this.state.getItemRoute(item) : this.state.getListRoute({ cluster_id: [item.id] });
    this.$router.push(route);
  }

  async deleteHandler() {
    for (let item of this.state.selectedItems) {
      try {
        await this.state.delete(item.id);
        this.state.selectedItems = [];
      } catch (e) {
        console.warn('delete.error ', e);
      }
    }
    await this.state.get();
  }

  async mergeHandler() {
    try {
      const ids = this.state.selectedItems.map((v) => v.id);
      await this.state.merge(ids);
      this.state.selectedItems = [];
    } catch (e) {
      console.warn('delete.error ', e);
    }
    await this.state.get();
  }

  getIsSelected(item: any): boolean {
    return this.state.selectedItems.findIndex((v) => v.id === item.id) > -1;
  }

  toggleSelect(v: boolean, item: any) {
    if (v) {
      this.state.selectedItems.push(item);
    } else {
      const index = this.state.selectedItems.findIndex((v) => v.id === item.id);
      if (index > -1) this.state.selectedItems.splice(index, 1);
    }
  }
}
